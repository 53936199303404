const scrollAnimation = () => {
  const animateItems = Array.from(
    document.querySelectorAll(
      '.js-animate-image, .js-animate-text, .js-animate-learn-more, .js-animate-content-item, .js-animate-panel-banner, .js-animate-sixty-year-logo, .js-animate-card'
    )
  )
  const updateSection = entry => {
    if (entry.isIntersecting) {
      entry.target.classList.add('-loaded')
    }
  }
  const intersectCallback = (entries, observer) => {
    for (let entry of entries) {
      updateSection(entry)
    }
  }
  const observer = new IntersectionObserver(intersectCallback)
  for (let elem of animateItems) {
    observer.observe(elem)
  }
}
window.addEventListener('load', scrollAnimation)
