import 'babel-polyfill'
import 'js/polyfills/customEvent'
import 'js/polyfills/closest'
import 'lazysizes'

// =utils
import 'js/utils/breakpoints'
import 'js/utils/detectTouch'
import 'js/utils/scroll-animation'

require('intersection-observer')

//object fit polyfill
import objectFitImages from 'object-fit-images'
objectFitImages()

if (document.querySelector('.typ img[alt=""]')) {
  import(/* webpackChunkName: "contentAltFix" */ 'js/utils/contentAltFix')
}

// debug mode
if (document.querySelector('body').classList.contains('-debug')) {
  import(/* webpackChunkName: "debugger" */ 'js/utils/debugger')
}

//===============================================================/
//  =components
//===============================================================/

if (document.querySelector('.cookie-consent')) {
  import(
    /* webpackChunkName: "compCookieConsent" */ 'js/components/cookieConsent'
  )
}

if (document.querySelector('.js-login')) {
  import(/* webpackChunkName: "componentLogin" */ 'js/components/login')
}

if (document.querySelector('.js-collapse')) {
  import(/* webpackChunkName: "collapse" */ 'js/components/collapse')
}

if (document.querySelector('.js-feature-carousel')) {
  import(
    /* webpackChunkName: "featureCarousel" */ 'js/components/featureCarousel'
  )
}

if (document.querySelector('.js-resource-filter-form')) {
  import(
    /* webpackChunkName: "resourceListFilters" */ 'js/components/resource-list-filters'
  )
}

if (document.querySelector('.js-resource-list-items')) {
  import(/* webpackChunkName: "resourceList" */ 'js/components/resource-list')
}

if (document.querySelector('.js-social-button-window')) {
  import(/* webpackChunkName: "socialShare" */ 'js/components/social-share')
}

if (document.querySelector('.js-subscription')) {
  import(/* webpackChunkName: "subscription" */ 'js/components/subscription')
}

if (document.querySelector('.js-author')) {
  import(/* webpackChunkName: "author" */ 'js/components/author')
}

if (document.querySelector('.js-user-search')) {
  import(/* webpackChunkName: "userSearch" */ 'js/components/userSearch')
}

if (document.querySelector('.js-inline-form')) {
  import(/* webpackChunkName: "inlineForm" */ 'js/components/inline-form')
}
if (document.querySelector('.typ')) {
  import(/* webpackChunkName: "typography" */ 'js/components/typography')
}

//===============================================================/
//  =blocks
//===============================================================/

if (document.querySelector('.js-media')) {
  import(
    /* webpackChunkName: "mediaAndContentBlock" */ 'js/blocks/mediaAndContentBlock'
  )
}

if (document.querySelector('.js-video-block')) {
  import(/* webpackChunkName: "videoBlock" */ 'js/blocks/videoBlock')
}

if (document.querySelector('.js-trending')) {
  import(
    /* webpackChunkName: "trendingArticlesBlock" */ 'js/blocks/trendingArticlesBlock'
  )
}

if (document.querySelector('.js-accordion')) {
  import(/* webpackChunkName: "accordionBlock" */ 'js/blocks/accordionBlock')
}

if (document.querySelector('.js-hero')) {
  import(/* webpackChunkName: "heroBlock" */ 'js/blocks/heroBlock')
  import(/* webpackChunkName: "docHeight" */ 'js/utils/doc-height')
}

if (document.querySelector('.js-testimonial')) {
  import(
    /* webpackChunkName: "testimonialBlock" */ 'js/blocks/testimonialBlock'
  )
}

if (document.querySelector('.js-standard-banner')) {
  import(
    /* webpackChunkName: "standardBannerBlock" */ 'js/blocks/standardBannerBlock'
  )
}

if (document.querySelector('.js-standard-banner')) {
  import(
    /* webpackChunkName: "standardBannerBlock" */ 'js/blocks/standardBannerBlock'
  )
}

if (document.querySelector('.js-map-block')) {
  import(/* webpackChunkName: "MapBlock" */ 'js/blocks/mapBlock')
}

//===============================================================/
//  =layout
//===============================================================/

if (document.querySelector('.js-navigation')) {
  import(/* webpackChunkName: "nav" */ 'js/layouts/nav')
}

if (document.querySelector('.js-back-to-top-btn')) {
  import(/* webpackChunkName: "footer" */ 'js/layouts/footer')
}

//===============================================================/
//  =pages
//===============================================================/

if (document.querySelector('.js-resources-list')) {
  import(
    /* webpackChunkName: "resourcesListPage" */ 'js/pages/resources-list-page'
  )
}
